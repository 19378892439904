import * as React from 'react';

function SvgShoppingBag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M443.188 442.208L415.956 142.56c-.768-8.256-7.68-14.56-15.968-14.56h-48V96c0-25.728-9.952-49.888-28.032-67.968C305.876 9.952 281.716 0 255.988 0c-52.928 0-96 43.072-96 96v32h-48c-8.288 0-15.2 6.304-15.936 14.56L68.82 442.208c-1.632 17.856 4.384 35.712 16.48 48.96S114.612 512 132.564 512h246.88c17.952 0 35.168-7.584 47.264-20.832s18.08-31.104 16.48-48.96zM191.988 96c0-35.296 28.704-64 64-64 17.184 0 33.28 6.624 45.344 18.656S319.988 78.816 319.988 96v32h-128V96zM403.06 469.6c-6.144 6.688-14.528 10.4-23.648 10.4H132.564c-9.088 0-17.504-3.712-23.616-10.432-6.144-6.72-9.056-15.392-8.224-24.48L126.612 160h33.376v48c0 8.832 7.168 16 16 16s16-7.168 16-16v-48h128v48c0 8.832 7.168 16 16 16s16-7.168 16-16v-48h33.376l25.92 285.12c.832 9.056-2.08 17.76-8.224 24.48z" />
    </svg>
  );
}

export default SvgShoppingBag;
