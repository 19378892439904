import * as React from 'react';

function SvgOffersMenu(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138 130" {...props}>
      <path
        d="M92,28.2c13.3,4,30.5,8.2,37.6,18.4c7.1,10.3,4,26.5-0.5,42.9c-4.6,16.3-10.7,32.7-22.4,34.7
  c-11.8,2-29.2-10.3-46.5-10.6c-17.2-0.3-34.3,11.4-38.9,7.1s3.2-24.8,2-39.7C22,66.1,11.6,56.8,7.4,43.1C3.2,29.5,5.1,11.5,14.9,6.5 c9.8-5.1,27.5,2.7,40.9,8.5C69.3,20.7,78.7,24.3,92,28.2z"
        fill="#f55a82"
      />
    </svg>
  );
}

export default SvgOffersMenu;
