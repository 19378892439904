import * as React from 'react';

function SvgNewArrivalsMenu(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 151.3 101.3"
      {...props}
    >
      <path
        d="M99.5,13.9c15.1,3.1,38.5,2.5,45.2,10.3s-3.4,24-11.9,37.8c-8.6,13.9-15.5,25.3-25.5,29.1 c-10,3.9-23,0.2-36.9,1.5c-14,1.2-28.8,7.5-42.1,4.7C15.1,94.5,3.4,82.7,3.4,70C3.3,57.3,14.8,43.9,25.1,35.5S44.5,23.9,52,18.3 S65.4,4.7,71.7,4.2C77.9,3.7,84.5,10.7,99.5,13.9z"
        fill="#fed88f"
      />
    </svg>
  );
}

export default SvgNewArrivalsMenu;
