import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Circle from 'components/Circle/Circle';
import Container from 'components/Container/Container';
import IconText from 'components/IconText/IconText';
import CartProductTileBlob from 'components/Icons/Project/CartProductTileBlob';
import Close from 'components/Icons/Project/Close';
import FreeShipping from 'components/Icons/Project/FreeShipping';
import Image from 'components/Image';

import { useCart } from 'hooks/useCart';

import fbqEvent from 'utilities/fbq';

import {
  overlay,
  open,
  outerContainer,
  container,
  bgImage,
  headerContainer,
  titleContainer,
  closeBtn,
  li,
  link,
  productsContainer,
  contentContainer,
  content,
  figure,
  blobContainer,
  blob,
  blobText,
  title,
  brand,
  price,
  details,
  figureContainer,
  actionsContainer,
  sumContainer,
  pricingText,
  pricingAmount,
  pricingContainer,
  sum,
  checkoutContainer,
  btnCheckout,
} from './CartOverlay.module.scss';
import { hrBlack } from 'styles/components/hr.module.scss';
import { btnLink } from 'styles/components/links.module.scss';

const circleStyle = {
  marginLeft: '0.5rem',
  background: '#000',
  color: '#fff',
};
const iconStyle = { margin: '1rem 0' };
const imageStyle = { position: 'absolute' };

const CartOverlay = ({ isOpen }) => {
  const { cart, totals, removeItem } = useCart();
  const freeTransfer =
    totals.priceWithoutFee && totals.priceWithoutFee.split('.')[0];

  const completeOrder = () => {
    fbqEvent('track', 'InitiateCheckout', {
      value: Number.parseFloat(totals.sum),
      currency: 'EUR',
      contents: cart.map((item) => ({
        id: item.product.sku || item.product.id,
        quantity: item.quantity,
      })),
    });
  };

  if (!cart || cart.length === 0) {
    return (
      <div className={`${overlay}${isOpen ? ' ' + open : ''}`}>
        <Container>
          <div className={outerContainer}>
            <StaticImage
              layout="fullWidth"
              quality={100}
              alt=""
              src="../../images/minicart.jpg"
              formats={['auto', 'webp', 'avif']}
              className={bgImage}
            />
            <div className={container}>
              <div className={headerContainer}>
                <div className={titleContainer}>
                  <span>Shopping Cart</span>
                  <Circle size="L" style={circleStyle}>
                    {cart.length}
                  </Circle>
                </div>
                <p>Το καλάθι είναι άδειο.</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={`${overlay}${isOpen ? ' ' + open : ''}`}>
      <Container>
        <div className={outerContainer}>
          <StaticImage
            layout="fullWidth"
            quality={100}
            alt=""
            src="../../images/minicart.jpg"
            formats={['auto', 'webp', 'avif']}
            className={bgImage}
          />
          <div className={container}>
            <div className={headerContainer}>
              <div className={titleContainer}>
                <span>Shopping Cart</span>
                <Circle size="L" style={circleStyle}>
                  {cart.length}
                </Circle>
              </div>
              <p>
                Έχετε {cart.length} {cart.length < 2 ? 'προϊόν' : 'προϊόντα'}{' '}
                στο καλάθι αγορών
              </p>
            </div>
            <ul className={productsContainer}>
              {cart.map((item) => (
                <li key={item.id} className={li}>
                  <div className={figureContainer}>
                    <Link to={item.relativePath} className={link}>
                      <figure className={figure}>
                        <Image
                          filename={item.product.images[0].hash}
                          alt={
                            item.product.images[0].alternativeText ||
                            item.product.title
                          }
                          styles={imageStyle}
                          width={100}
                        />
                        {item.quantity > 1 && (
                          <div className={blobContainer}>
                            <CartProductTileBlob className={blob} />
                            <span className={blobText}>x{item.quantity}</span>
                          </div>
                        )}
                      </figure>
                    </Link>
                  </div>
                  <div className={contentContainer}>
                    <Link to={item.relativePath} className={link}>
                      <div className={content}>
                        <h4 className={title}>{item.product.title}</h4>
                        <h4 className={brand}>
                          {item.product &&
                            item.product.brand &&
                            item.product.brand.title}
                        </h4>
                        <p className={price}>
                          {((item.price * item.quantity) / 100).toFixed(2)} €
                        </p>
                        <p className={details}>
                          Χρώμα: <b>{item.product.color}</b>
                        </p>
                        <p className={details}>
                          Μέγεθος: <b>{item.product.size}</b>
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className={actionsContainer}>
                    <button
                      type="button"
                      className={closeBtn}
                      onClick={(ev) => {
                        ev.preventDefault();
                        removeItem(item);
                      }}
                    >
                      <Close width="1rem" height="1rem" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            {totals && totals.sum && (
              <div className={sumContainer}>
                <>
                  {totals.hasCashShippingFee && (
                    <h6 className={pricingContainer}>
                      <span className={pricingText}>ΚΟΣΤΟΣ ΑΝΤΙΚΑΤΑΒΟΛΗΣ</span>{' '}
                      <span className={pricingAmount}>
                        {totals.cashShippingFee} €
                      </span>
                    </h6>
                  )}
                  <>
                    <h6 className={pricingContainer}>
                      <span className={pricingText}>ΜΕΤΑΦΟΡΙΚΑ</span>{' '}
                      <span className={pricingAmount}>
                        {totals.hasShippingFee ? totals.shippingFee : '0.00'} €
                      </span>
                    </h6>
                    <h6 className={`${pricingContainer} ${sum}`}>
                      <span className={pricingText}>ΣΥΝΟΛΟ</span>{' '}
                      <span className={pricingAmount}>
                        <b>{totals.sum} €</b>
                      </span>
                    </h6>
                  </>
                  <hr className={hrBlack} />
                </>
                <div className={checkoutContainer}>
                  <IconText
                    title="ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ"
                    icon={<FreeShipping />}
                    text={`με αγορές άνω των ${freeTransfer || '59'} €`}
                    iconSize="large"
                    style={iconStyle}
                  />
                  <Link
                    to={'/checkout/'}
                    onClick={completeOrder}
                    className={`${btnLink} ${btnCheckout}`}
                  >
                    ΟΛΟΚΛΗΡΩΣΗ ΑΓΟΡΩΝ
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

// {totals.hasCashShippingFee && (
//   <h6 className={pricingContainer}>
//     <span className={pricingText}>ΚΟΣΤΟΣ ΑΝΤΙΚΑΤΑΒΟΛΗΣ</span>{' '}
//     <span className={pricingAmount}>
//       {totals.cashShippingFee} €
//     </span>
//   </h6>
// )}
export default CartOverlay;
