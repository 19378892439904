// extracted by mini-css-extract-plugin
export var mainHeader = "typography-module--main-header--1NrtB";
export var mainHeaderDark = "typography-module--main-header-dark--1K0iS";
export var secondaryHeader = "typography-module--secondary-header--1Z1Ya";
export var secondaryHeaderDark = "typography-module--secondary-header-dark--w1Fvy";
export var altHeader = "typography-module--alt-header--25o5L";
export var bannerHeader = "typography-module--banner-header--1mg-6";
export var productHeader = "typography-module--product-header--uTANg";
export var sticker = "typography-module--sticker--3Wdok";
export var mainDescription = "typography-module--main-description--3jbAO";
export var mainDescriptionDark = "typography-module--main-description-dark--yjvWS";