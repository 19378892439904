import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io5';

import Logo from 'components/Icons/Logo';

import * as styles from './Widgets.module.scss';

const query = graphql`
  query Widgets {
    allStrapiCategory(
      filter: { enabled: { eq: true }, visibleToMainMenu: { eq: true } }
      sort: { fields: mainMenuIndex, order: ASC }
    ) {
      nodes {
        title
        slug
        strapiParent {
          id
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        tel
        email
        address
        fb
        instagram
      }
    }
  }
`;

const Widgets = () => {
  const {
    allStrapiCategory: { nodes: items },
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const menu = items.filter((item) => item.strapiParent === null);
  const half = Math.ceil(menu.length / 2);

  return (
    <div className={styles.widgetContainer}>
      <div className={styles.widgetInner}>
        <div className={styles.container}>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              {menu.slice(0, half).map((item) => (
                <li key={item.slug}>
                  <Link to={`/${item.slug}/`} className={styles.link}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              {menu.slice(-half).map((item) => (
                <li key={item.slug}>
                  <Link to={`/${item.slug}/`} className={styles.link}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.columnCenter}>
            <Link
              aria-label={`${info.title} - ${info.description}`}
              className={styles.brand}
              to="/"
            >
              <Logo aria-label={info.title} />
            </Link>
            <p>{info.tel}</p>
            <p>
              <a className={styles.link} href={`mailto:${info.email}`}>
                {info.email}
              </a>
            </p>
            <p>{info.address}</p>
            <div className={styles.socialContainer}>
              <a
                href={info.fb}
                target="_blank"
                rel="noreferrer"
                className={styles.logo}
                aria-label={`${info.title} facebook page`}
              >
                <IoLogoFacebook size="2rem" />
              </a>
              <a
                href={info.instagram}
                target="_blank"
                rel="noreferrer"
                className={styles.logo}
                aria-label={`${info.title} instagram account`}
              >
                <IoLogoInstagram size="2rem" />
              </a>
            </div>
          </div>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              <li>
                <Link to={'/app/account'} className={styles.link}>
                  ΣΥΝΔΕΣΗ
                </Link>
              </li>
              <li>
                <Link to={'/app/account'} className={styles.link}>
                  ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ
                </Link>
              </li>
              <li>
                <Link to={'/app/history'} className={styles.link}>
                  ΙΣΤΟΡΙΚΟ ΠΑΡΑΓΓΕΛΙΩΝ
                </Link>
              </li>
              <li>
                <Link to={'/etaireia/'} className={styles.link}>
                  Η ΕΤΑΙΡΕΙΑ
                </Link>
              </li>
              <li>
                <Link to={'/epikoinonia/'} className={styles.link}>
                  ΕΠΙΚΟΙΝΩΝΙΑ
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              <li>
                <Link to={'/oroi-xrhshs/'} className={styles.link}>
                  ΟΡΟΙ ΧΡΗΣΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/tropoi-plhromhs/'} className={styles.link}>
                  ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/tropoi-apostolhs/'} className={styles.link}>
                  ΤΡΟΠΟΙ ΑΠΟΣΤΟΛΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/asfaleia-synallagwn/'} className={styles.link}>
                  ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.paymentsContainer}>
          <div className={styles.paymentsImageContainer}>
            <StaticImage
              src="../../images/payment-methods-horizontal.png"
              alt="Alpha e-commerce payment methods, credit cards. Visa, mastercard, maestro, amex, masterpass"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widgets;
