import React from 'react';

// import React, { useEffect } from 'react';
import CartIcon from 'components/CartIcon/CartIcon';
import Close from 'components/Icons/Project/Close';

// import CartOverlay from 'components/CartOverlay/CartOverlay';
import { useCartContext } from 'hooks/useCartContext';

// import useToggle from 'hooks/useToggle';
import { container, notification } from './MenuCart.module.scss';
import { menuIconBtn } from 'styles/components/buttons.module.scss';

const btnStyle = { width: '100%', height: '100%' };

const MenuCart = ({ isOpen, toggle }) => {
  const { totals } = useCartContext();
  // const { value, toggle } = useToggle();
  // const { toggle } = useToggle();

  // useEffect(() => {
  //   value
  //     ? document.documentElement.setAttribute('data-is-dialog-open', 'true')
  //     : document.documentElement.removeAttribute('data-is-dialog-open');
  //   return () => {
  //     document.documentElement.removeAttribute('data-is-dialog-open');
  //   };
  // }, [value]);

  return (
    <>
      <div className={container}>
        <button
          onClick={toggle}
          className={menuIconBtn}
          style={btnStyle}
          aria-label="Αναζήτησε κοσμήματα, δαχτυλίδια, κολιέ, βραχιόλια, ρολόγια, αξεσουάρ"
        >
          {isOpen ? (
            <Close width="1.15rem" height="1.15rem" />
          ) : (
            <>
              <CartIcon width="1.5rem" heigth="1.5rem" />
              {totals && totals.quantity > 0 && (
                <span className={notification}>{totals.quantity}</span>
              )}
            </>
          )}
        </button>
      </div>
      {/* <CartOverlay isOpen={value} close={toggle} /> */}
    </>
  );
};

export default MenuCart;
