// import { Link, navigate } from 'gatsby';
import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { AiOutlineSearch } from 'react-icons/ai';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Close from 'components/Icons/Project/Close';
import TitleBrand from 'components/TitleBrand/TitleBrand';

// import Logo from 'components/Icons/Logo';
// import useData from 'data/useData';
import {
  container,
  header,
  main,
  form,
  label,
  searchButton,
  searchInput,
  open,
  closeBtn,
} from './SearchOverlay.module.scss';

// const defaultSearches = [
//   { term: 'Σκουλαρίκι' },
//   { term: 'Δαχτυλίδι' },
//   { term: 'Ρολόϊ' },
//   { term: 'Πορτοφόλι' },
//   { term: 'Βραχιόλι' },
//   { term: 'Τσάντα' },
// ];

const SearchOverlay = ({ close, isOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('search', {
    required: 'Το πεδίο είναι υποχρεωτικό για να γίνει η αναζήτηση.',
    minLength: {
      value: 3,
      message: 'Η αναζήτηση πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες',
    },
  });
  // const { data: terms } = useData(isOpen ? `/search-terms` : null);
  const searchRef = useRef(null);

  const onSubmit = (ev) => {
    if (typeof window !== 'undefined') {
      navigate(`/anazitisi?term=${ev.search}`);
    }
    close();
  };

  useEffect(() => {
    let mounted = true;
    if (isOpen) {
      setTimeout(() => {
        mounted && searchRef.current.focus();
      }, 250);
    }
    return () => {
      mounted = false;
    };
  }, [isOpen]);

  return (
    <div className={`${container}${isOpen ? ' ' + open : ''}`}>
      <header className={header}>
        {/* <Logo
          className={logo}
          fill="#000"
          stroke="#000"
          aria-label="Faidra Concept"
        /> */}
        <button
          className={closeBtn}
          onClick={close}
          aria-label="Κλείσιμο αναζήτησης"
        >
          <Close width="1.15rem" height="1.15rem" />
        </button>
      </header>
      <main className={main}>
        <form role="search" onSubmit={handleSubmit(onSubmit)} className={form}>
          <TitleBrand text="Search" />
          <label htmlFor="search" className={label}>
            <button type="submit" className={searchButton}>
              <IconContext.Provider value={{ color: '#000', size: '28px' }}>
                <AiOutlineSearch />
              </IconContext.Provider>
            </button>
            <input
              {...rest}
              type="search"
              name="search"
              ref={(e) => {
                ref(e);
                searchRef.current = e;
              }}
              id="search"
              placeholder="Αναζητήστε στο κατάστημα…"
              aria-label="Αναζήτηση προϊόντων - ρούχα, ενδύματα, αξεσουάρ, προσφορές"
              className={searchInput}
            />
            {errors.search && (
              <ErrorMessage>{errors.search.message}</ErrorMessage>
            )}
          </label>
          {/* <div className={popularContainer}>
            <h2 className={popularTitle}>ΔΗΜΟΦΙΛΕΙΣ ΑΝΑΖΗΤΗΣΕΙΣ</h2>
            <div className={popularLinks}>
              {terms && Array.isArray(terms) && terms.length > 0
                ? terms.map(({ term }) => (
                    <Link
                      key={term}
                      className={popularLink}
                      to={`/anazitisi?term=${term}`}
                    >
                      {term}
                    </Link>
                  ))
                : defaultSearches.map(({ term }) => (
                    <Link
                      key={term}
                      className={popularLink}
                      to={`/anazitisi?term=${term}`}
                    >
                      {term}
                    </Link>
                  ))}
            </div>
          </div> */}
        </form>
      </main>
    </div>
  );
};

export default SearchOverlay;
