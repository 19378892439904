import React, { useRef } from 'react';

import isTouchDevice from 'utilities/isTouchDevice';

import * as styles from './Hamburger.module.scss';

const Hamburger = ({ handleClick, isOpen }) => {
  const noTouch = useRef(typeof window !== 'undefined' && !isTouchDevice());
  return (
    <div data-no-touch={noTouch.current} className={`${styles.container}`}>
      <button
        type="button"
        onClick={handleClick}
        className={`${styles.icon} ${isOpen ? styles.open : ''}`}
        aria-label="Menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default Hamburger;
