import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query Menu {
    allStrapiCategory(
      filter: {
        enabled: { eq: true }
        visibleToMainMenu: { eq: true }
        slug: { nin: ["offers", "new-arrivals"] }
      }
      sort: { fields: mainMenuIndex, order: ASC }
    ) {
      nodes {
        mainMenuIndex
        strapiId
        title
        visibleToMainMenu
        slug
        strapiParent {
          id
        }
      }
    }
  }
`;

const useMenu = () => {
  const {
    allStrapiCategory: { nodes: items },
  } = useStaticQuery(query);
  let menu = [];
  let submenus = {};
  items.forEach((item) =>
    item.strapiParent === null
      ? menu.push(item)
      : submenus[item.strapiParent.id]
      ? submenus[item.strapiParent.id].push(item)
      : (submenus[item.strapiParent.id] = [item])
  );
  return { menu, submenus };
};

export default useMenu;
