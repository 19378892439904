import * as React from 'react';

function SvgCartProductTileBlob(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.1 98.6" {...props}>
      <path
        d="M69.6 28.6C47.9 12.5 26.2-5.2 13.2 1.4.2 7.9-4.1 38.7 4.5 61.1c8.7 22.5 30.2 36.6 54.2 37.4 23.9.8 50.1-11.9 52.3-25.5 2.3-13.6-19.6-28.2-41.4-44.4z"
        fill="#d0e8e3"
      />
    </svg>
  );
}

export default SvgCartProductTileBlob;
