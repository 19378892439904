// extracted by mini-css-extract-plugin
export var widgetContainer = "Widgets-module--widgetContainer--2e_G8";
export var widgetInner = "Widgets-module--widgetInner--2BGSb";
export var brand = "Widgets-module--brand--1u0k0";
export var container = "Widgets-module--container--1qGBP";
export var column = "Widgets-module--column--2ZrE-";
export var columnCenter = "Widgets-module--columnCenter--giBeG";
export var usefulLinks = "Widgets-module--usefulLinks--glm2t";
export var link = "Widgets-module--link--161LQ";
export var paymentsContainer = "Widgets-module--payments-container--3jVul";
export var paymentsImageContainer = "Widgets-module--paymentsImageContainer--3em2x";
export var socialContainer = "Widgets-module--social-container--3zm4G";
export var logo = "Widgets-module--logo--3RSxL";