import React from 'react';

import ShoppingBag from 'components/Icons/Project/ShoppingBag';

// import { IconContext } from 'react-icons';
// import { IoCartOutline } from 'react-icons/io5';

// const iconSize = { size: '1.5rem' };

/* <IconContext.Provider value={{ ...iconSize, ...props }}>
      <IoCartOutline />
    </IconContext.Provider> */
const CartIcon = (props) => {
  return <ShoppingBag {...props} />;
};

export default CartIcon;
