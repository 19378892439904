// extracted by mini-css-extract-plugin
export var overlay = "CartOverlay-module--overlay--1knhw";
export var open = "CartOverlay-module--open--3bcQy";
export var outerContainer = "CartOverlay-module--outerContainer--2Fcqc";
export var bgImage = "CartOverlay-module--bgImage--IwYBG";
export var container = "CartOverlay-module--container--2STg4";
export var headerContainer = "CartOverlay-module--headerContainer--1oY47";
export var titleContainer = "CartOverlay-module--titleContainer--18klR";
export var productsContainer = "CartOverlay-module--productsContainer--2NPkQ";
export var li = "CartOverlay-module--li--1_bWX";
export var contentContainer = "CartOverlay-module--contentContainer--2SBka";
export var content = "CartOverlay-module--content--1bnjz";
export var figureContainer = "CartOverlay-module--figureContainer--9Bi7k";
export var figure = "CartOverlay-module--figure--1yvdZ";
export var blobContainer = "CartOverlay-module--blobContainer--3r5Gw";
export var blob = "CartOverlay-module--blob--2Nb_I";
export var blobText = "CartOverlay-module--blobText--1a59B";
export var price = "CartOverlay-module--price--nGP3_";
export var title = "CartOverlay-module--title--3pKAL";
export var brand = "CartOverlay-module--brand--2169e";
export var details = "CartOverlay-module--details--2ocBw";
export var actionsContainer = "CartOverlay-module--actionsContainer--2Z3lM";
export var closeBtn = "CartOverlay-module--close-btn--31TCN";
export var pricingContainer = "CartOverlay-module--pricingContainer--3fDX1";
export var pricingText = "CartOverlay-module--pricing-text--3njJP";
export var pricingAmount = "CartOverlay-module--pricing-amount--1yhwl";
export var link = "CartOverlay-module--link--lbOjv";
export var sumContainer = "CartOverlay-module--sum-container--B_XPP";
export var sum = "CartOverlay-module--sum--2YtbH";
export var checkoutContainer = "CartOverlay-module--checkoutContainer--2PL47";
export var btnCheckout = "CartOverlay-module--btn-checkout--2NDCb";