import * as React from 'react';

function SvgFreeShipping(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M483.7 259.5h-43V143.2c0-15.5-12.6-28.1-28.1-28.1h-64.8V87.2c0-15-12.2-27.2-27.2-27.2H27.2C12.2 60 0 72.2 0 87.2v12c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-12C15 80.5 20.5 75 27.2 75h293.4c6.7 0 12.2 5.5 12.2 12.2v236.2H15v-189c0-4.1-3.4-7.5-7.5-7.5S0 130.2 0 134.4v254.2c0 15 12.2 27.2 27.2 27.2h17.2c3.8 20.6 21.8 36.2 43.5 36.2s39.7-15.6 43.5-36.2h119.3c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5H131.5c-3.4-21-21.6-37.2-43.6-37.2s-40.3 16.1-43.6 37.2H27.2c-6.7 0-12.2-5.5-12.2-12.2v-50.2h317.8v62.4h-47.7c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h97.3c3.8 20.6 21.8 36.2 43.5 36.2s39.7-15.6 43.5-36.2h14.7c15.5 0 28.1-12.6 28.1-28.1v-100c-.2-15.5-12.9-28.2-28.5-28.2zM87.9 378.6c16.1 0 29.2 13.1 29.2 29.2S104 437 87.9 437s-29.2-13.1-29.2-29.2 13.1-29.2 29.2-29.2zM425.8 437c-16.1 0-29.2-13.1-29.2-29.2s13.1-29.2 29.2-29.2 29.2 13.1 29.2 29.2-13.1 29.2-29.2 29.2zM497 328.4h-20c-4.1 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5h20v15zm0-30h-20c-12.4 0-22.5 10.1-22.5 22.5s10.1 22.5 22.5 22.5h20v44.3c0 7.2-5.9 13.1-13.1 13.1h-14.5c-3.4-21-21.6-37.2-43.6-37.2s-40.3 16.1-43.6 37.2h-34.4V130.1h64.8c7.2 0 13.1 5.9 13.1 13.1v1.7h-50.2c-4.1 0-7.5 3.4-7.5 7.5V267c0 4.1 3.4 7.5 7.5 7.5h108.3c7.3 0 13.3 6 13.3 13.3l-.1 10.6zm-71.4-138.5v99.6h-42.7v-99.6h42.7z" />
      <path d="M392.8 298.4h-17.3c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h17.3c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5zm-174.9-116c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-30c-4.1 0-7.5 3.4-7.5 7.5v60c0 4.1 3.4 7.5 7.5 7.5h30c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-22.5v-15h22.5c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-22.5v-15h22.5zm59 0c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-30c-4.1 0-7.5 3.4-7.5 7.5v60c0 4.1 3.4 7.5 7.5 7.5h30c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-22.5v-15h22.5c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-22.5v-15h22.5zm-176 0c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-30c-4.1 0-7.5 3.4-7.5 7.5v60c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-22.5h22.5c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5H78.4v-15h22.5zm65.5 22.5v-30c0-4.1-3.4-7.5-7.5-7.5h-30c-4.1 0-7.5 3.4-7.5 7.5v60c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-20.2l16.2 24.4c1.4 2.2 3.8 3.3 6.2 3.3 1.4 0 2.9-.4 4.2-1.3 3.4-2.3 4.4-7 2.1-10.4l-12.2-18.3h6c4.2 0 7.5-3.3 7.5-7.5zm-15-7.5h-15v-15h15v15z" />
    </svg>
  );
}

export default SvgFreeShipping;
