import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
// import { graphql, Link, useStaticQuery } from 'gatsby';
// import React, { useEffect, useRef, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';

import CartOverlay from 'components/CartOverlay/CartOverlay';
import Hamburger from 'components/Hamburger/Hamburger';
import NewArrivalsMenu from 'components/Icons/Project/NewArrivalsMenu';
import OffersMenu from 'components/Icons/Project/OffersMenu';
// import Fb from 'components/Icons/Fb';
// import Insta from 'components/Icons/Insta';
import MenuCart from 'components/Menu/MenuCart';
import MenuUser from 'components/Menu/MenuUser';
import SearchWidget from 'components/Search/SearchWidget';

import useToggle from 'hooks/useToggle';

// import isTouchDevice from 'utilities/isTouchDevice';
import useMenu from 'queries/menu';

import * as styles from './Menu.module.scss';
import { transparentBtn } from 'styles/components/buttons.module.scss';

// const query = graphql`
//   query SiteMetaMenu {
//     site {
//       siteMetadata {
//         title
//         fb
//         instagram
//       }
//     }
//   }
// `;

const Menu = () => {
  // const {
  //   site: { siteMetadata: info },
  // } = useStaticQuery(query);
  // const noTouch = useRef(typeof window !== 'undefined' && !isTouchDevice());
  const [isOpen, setIsOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const { menu, submenus } = useMenu();
  const { value, toggle } = useToggle();
  // only for the links with queries
  const { pathname } = useLocation();
  const isInProiontaPage = pathname.includes('/proionta');

  useEffect(() => {
    isOpen
      ? document.documentElement.setAttribute('data-is-menu-open', 'true')
      : document.documentElement.removeAttribute('data-is-menu-open');
    return () => {
      document.documentElement.removeAttribute('data-is-menu-open');
    };
  }, [isOpen]);

  useEffect(() => {
    value
      ? document.documentElement.setAttribute('data-is-dialog-open', 'true')
      : document.documentElement.removeAttribute('data-is-dialog-open');
    return () => {
      document.documentElement.removeAttribute('data-is-dialog-open');
    };
  }, [value]);
  return (
    <nav data-no-touch={false} className={styles.nav}>
      <div
        className={`${styles.menuContainer}${isOpen ? ' ' + styles.open : ''}`}
      >
        <div className={styles.sideLogo}>faidra</div>
        <div className={styles.menuInnerContainer}>
          <section className={styles.promoSection}>
            <Link
              to="/offers/"
              aria-label="Offers - Προσφορές"
              className={styles.promoLink}
            >
              <OffersMenu width="100%" />
              <h3 className={styles.promoTitle}>Offers</h3>
            </Link>
            <Link
              to="/new-arrivals/"
              aria-label="New arrivals - Νέες παραλαβές"
              className={styles.promoLink}
            >
              <NewArrivalsMenu width="100%" />
              <h3 className={styles.promoTitle}>New arrivals</h3>
            </Link>
          </section>
          <div className={styles.mainNav}>
            <ul className={`${styles.navList}`}>
              {menu.map((item, index) => (
                <li key={item.strapiId} className={styles.listItem}>
                  <Link to={`/${item.slug}`} className={styles.link}>
                    {item.title}
                    {submenus[item.strapiId] && (
                      <button
                        type="button"
                        className={`${transparentBtn} ${styles.submenuIconToggle}`}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setOpenIndex(openIndex === index ? null : index);
                        }}
                      >
                        <IconContext.Provider value={{ color: '#fff' }}>
                          {index !== openIndex ? (
                            <FiChevronDown />
                          ) : (
                            <FiChevronUp />
                          )}
                        </IconContext.Provider>
                      </button>
                    )}
                  </Link>
                  {submenus[item.strapiId] && (
                    <ul
                      className={`${styles.submenu}${
                        openIndex === index ? ' ' + styles.submenuOpen : ''
                      }`}
                    >
                      {submenus[item.strapiId].map((subitem) => (
                        <li
                          key={subitem.strapiId}
                          className={styles.submenuItem}
                        >
                          <Link
                            to={`/${item.slug}/${subitem.slug}`}
                            className={styles.link}
                          >
                            <FiChevronRight className={styles.submenuIcon} />
                            {subitem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              {/* <li className={`${styles.listItem} ${styles.hideDesktop}`}>
          <Link className={styles.link} to="/etaireia">
            Η ΕΤΑΙΡΕΙΑ
          </Link>
        </li>
        <li className={`${styles.listItem} ${styles.hideDesktop}`}>
          <Link className={styles.link} to="/epikoinonia">
            ΕΠΙΚΟΙΝΩΝΙΑ
          </Link>
        </li> */}
              {/* <li
          className={`${styles.listItem} ${styles.socialContainer} ${styles.hideDesktop}`}
        >
          <a
            href={info.fb}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} facebook page`}
          >
            <Fb fill={'#fff'} />
          </a>
          <a
            href={info.instagram}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} instagram page`}
          >
            <Insta fill={'#fff'} />
          </a>
        </li> */}
            </ul>
            <div>
              <h5 className={styles.navBrandsTitle}>SHOP BY BRAND</h5>
              {isInProiontaPage ? (
                <div className={styles.navBrands}>
                  <a
                    href="/proionta?brand=STUDIO%2083"
                    className={styles.brandLink}
                  >
                    STUDIO 83
                  </a>
                  <a
                    href="/proionta?brand=MADAME SHOU SHOU"
                    className={styles.brandLink}
                  >
                    MADAME SHOU SHOU
                  </a>
                  <a
                    href="/proionta?brand=NOLAH%20"
                    className={styles.brandLink}
                  >
                    NOLAH
                  </a>
                  <a href="/proionta?brand=AOS" className={styles.brandLink}>
                    AOS
                  </a>
                  <a href="/proionta?brand=IRAIDA" className={styles.brandLink}>
                    IRAIDA
                  </a>
                </div>
              ) : (
                <div className={styles.navBrands}>
                  <Link
                    to="/proionta?brand=STUDIO%2083"
                    className={styles.brandLink}
                  >
                    STUDIO 83
                  </Link>
                  <Link
                    to="/proionta?brand=MADAME SHOU SHOU"
                    className={styles.brandLink}
                  >
                    MADAME SHOU SHOU
                  </Link>
                  <Link
                    to="/proionta?brand=NOLAH%20"
                    className={styles.brandLink}
                  >
                    NOLAH
                  </Link>
                  <Link to="/proionta?brand=AOS" className={styles.brandLink}>
                    AOS
                  </Link>
                  <Link
                    to="/proionta?brand=IRAIDA"
                    className={styles.brandLink}
                  >
                    IRAIDA
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className={styles.iconsList}>
        <li className={styles.icon}>
          <SearchWidget />
        </li>
        <li className={styles.icon}>
          <MenuUser />
        </li>
        <li
          className={`${styles.cartIcon}${
            value ? ' ' + styles.cartIconOpen : ''
          }`}
        >
          <MenuCart isOpen={value} toggle={toggle} />
        </li>
      </ul>
      <Hamburger
        isOpen={isOpen}
        handleClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <CartOverlay isOpen={value} />
    </nav>
  );
};

export default Menu;
